<template>
  <page-list
    :list="list"
    placeholder="自动匹配ID搜索项，ID多个搜索用英文竖线"
    :showSearchbox="false"
    :columns="columns"
    :group-actions="groupActions"
    :single-actions="singleActions" />
</template>

<script>

import {
  // getFilter,
  getCreatedAtFilter,
} from '@/utils/common/tableFilter'
import WindowsMixin from '@/mixins/windows'
import ListMixin from '@/mixins/list'
import SingleActionsMixin from '../mixins/singleActions'
import ColumnsMixin from '../mixins/columns'

export default {
  name: 'NetworkMappingList',
  mixins: [WindowsMixin, ListMixin, ColumnsMixin, SingleActionsMixin],
  props: {
    id: String,
    getParams: {
      type: [Function, Object],
    },
    cloudEnv: String,
  },
  data () {
    return {
      list: this.$list.createList(this, {
        id: this.id,
        resource: 'network_mappings',
        getParams: this.getParam,
        filterOptions: {
          // instance_type_category: {
          //   field: 'instance_type_category',
          //   label: '套餐类型（名称）',
          // },
          created_at: getCreatedAtFilter(),
        },
        hiddenColumns: [],
      }),
      exportDataOptions: {
        items: [
          { label: 'ID', key: 'id' },
          { label: '运营商', key: 'operator_key' },
          { label: '区域', key: 'mapping_name' },
          { label: 'IP子网', key: 'network_name' },
        ],
      },
      groupActions: [
        {
          label: this.$t('compute.perform_create'),
          permission: 'serverinstancetypes_create',
          action: () => {
            this.createDialog('CreateNetworkMappingDialog', {
              title: this.$t('compute.perform_create'),
              onManager: this.onManager,
            })
          },
          meta: () => {
            const meta = {
              buttonType: 'primary',
              validate: true,
            }
            return meta
          },
        },
        {
          label: this.$t('common.batchAction'),
          actions: () => {
            return [
              {
                label: this.$t('compute.perform_delete'),
                permission: 'serverinstancetypes_delete',
                action: () => {
                  this.createDialog('DeleteResDialog', {
                    vm: this,
                    data: this.list.selectedItems,
                    columns: this.columns,
                    title: this.$t('compute.text_1053'),
                    name: this.$t('dictionary.sku'),
                    onManager: this.onManager,
                  })
                },
                meta: () => {
                  return this.$getDeleteResult(this.list.selectedItems)
                },
              },
            ]
          },
        },
      ],
    }
  },
  created () {
    this.list.fetchData()
  },
  methods: {
    getParam () {
      const ret = {
        details: true,
      }
      return ret
    },
  },
}
</script>
