import {
  // getProjectTableColumn,
  // getRegionTableColumn,
  // getStatusTableColumn,
  getEnabledTableColumn,
  // getCopyWithContentTableColumn,
  // getNameDescriptionTableColumn,
  // getBillingTypeTableColumn,
  // getOsArch,
  // getTimeTableColumn,
} from '@/utils/common/tableColumn'
// import SystemIcon from '@/sections/SystemIcon'
// import { sizestr } from '@/utils/utils'
// import i18n from '@/locales'
// import { PRODUCT_TYPES } from '../../../constant'

export default {
  created () {
    this.columns = [
      // getNameDescriptionTableColumn({
      //   onManager: this.onManager,
      //   hideField: true,
      //   // addLock: true,
      //   // addBackup: true,
      //   // formRules: [
      //   //   { required: true, message: i18n.t('compute.text_210') },
      //   //   { validator: this.$validate('resourceCreateName') },
      //   // ],
      //   slotCallback: row => {
      //     return (
      //       <side-page-trigger onTrigger={ () => this.handleOpenSidepage(row) }>{ row.name }</side-page-trigger>
      //     )
      //   },
      // }),
      {
        field: 'dict_type',
        title: '字典类型',
      },
      {
        field: 'name',
        title: '字典标签',
      },
      {
        field: 'dict_value',
        title: '字典值',
      },
      {
        field: 'priority',
        title: '字典排序',
      },
      getEnabledTableColumn(),
    ]
  },
}
