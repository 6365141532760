import { getEnabledSwitchActions } from '@/utils/common/tableActions'
import i18n from '@/locales'
export default {
  created () {
    this.singleActions = [
      {
        label: '修改',
        permission: 'dict_types_update',
        action: row => {
          // 跳转详情
          this.createDialog('DictTypeUpdateDialog', {
            list: this.list,
            data: row,
            onManager: this.onManager,
          })
        },
      },
      {
        label: i18n.t('system.text_153'),
        actions: row => {
          return [
            ...getEnabledSwitchActions(this, row, ['dict_types_perform_enable', 'dict_types_perform_disable'], {
              actions: [
                async (row) => {
                  this.onManager('performAction', {
                    id: row.id,
                    managerArgs: {
                      action: 'enable',
                    },
                  })
                },
                async (row) => {
                  this.onManager('performAction', {
                    id: row.id,
                    managerArgs: {
                      action: 'disable',
                    },
                  })
                },
              ],
            }),
            {
              label: i18n.t('compute.perform_delete'),
              permission: 'dict_types_delete',
              action: obj => {
                this.createDialog('DeleteResDialog', {
                  vm: this,
                  data: [obj],
                  columns: this.columns,
                  title: i18n.t('compute.perform_delete'),
                  onManager: this.onManager,
                  name: '删除',
                })
              },
              meta: obj => this.$getDeleteResult(obj),
            },
          ]
        },
      },
      // {
      //   label: '新建',
      //   // permission: 'product_create',
      //   action: obj => {
      //     this.$router.push({
      //       path: '/product/create',
      //       query: {
      //         id: obj.id,
      //       },
      //     })
      //   },
      //   // meta: obj => {
      //   //   const isReady = obj.status === 'ready'
      //   //   return {
      //   //     validate: isReady,
      //   //     tooltip: !isReady && i18n.t('compute.text_1048'),
      //   //   }
      //   // },
      // },

    ]
  },
}
