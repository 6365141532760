// import { chargeTypeColumn } from '../utils'
import {
  // getEnabledTableColumn,
  // getNameDescriptionTableColumn,
  // getStatusTableColumn,
  // getBrandTableColumn,
  getRegionTableColumn,
  getTimeTableColumn,
} from '@/utils/common/tableColumn'
// import { sizestr } from '@/utils/utils'
// import i18n from '@/locales'

export default {
  computed: {
    columns () {
      const columns = [
        {
          field: 'operator_key',
          title: '运营商',
          slots: {
            default: ({ row }) => {
              const item = this.getDictDatas(this.DICT_TYPE.NETWORK_OPERATOR).find(item => item.value === row.operator_key)
              return [<span>{item?.label || '-'}</span>]
            },
          },
        },
        getRegionTableColumn(),
        {
          field: 'network_name',
          title: 'IP子网',
        },
        getTimeTableColumn(),
      ]
      return columns
    },
  },
}
