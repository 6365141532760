// import { getEnabledSwitchActions } from '@/utils/common/tableActions'
import i18n from '@/locales'
// import { findPlatform } from '@/utils/common/hypervisor'

export default {
  computed: {
    singleActions () {
      const singleActions = [
        {
          label: i18n.t('compute.perform_delete'),
          // permission: 'serverinstancetypes_delete',
          action: (obj) => {
            this.createDialog('DeleteResDialog', {
              vm: this,
              data: [obj],
              columns: this.columns,
              title: i18n.t('compute.perform_delete'),
              name: this.$t('dictionary.network_mapping'),
              onManager: this.onManager,
            })
          },
          // meta: (obj) => {
          //   return this.$getDeleteResult(obj)
          // },
        },
      ]
      return singleActions
    },
  },
}
