import {
  // getProjectTableColumn,
  // getRegionTableColumn,
  // getStatusTableColumn,
  getEnabledTableColumn,
  // getCopyWithContentTableColumn,
  getNameDescriptionTableColumn,
  // getBillingTypeTableColumn,
  // getOsArch,
  // getTimeTableColumn,
} from '@/utils/common/tableColumn'
// import SystemIcon from '@/sections/SystemIcon'
// import { sizestr } from '@/utils/utils'
// import i18n from '@/locales'
// import { PRODUCT_TYPES } from '../../../constant'

export default {
  created () {
    this.columns = [
      getNameDescriptionTableColumn({
        onManager: this.onManager,
        hideField: true,
        // addLock: true,
        // addBackup: true,
        // formRules: [
        //   { required: true, message: i18n.t('compute.text_210') },
        //   { validator: this.$validate('resourceCreateName') },
        // ],
        slotCallback: row => {
          return (
            <a onClick={ () => this.handleOpenDictData(row) }>{ row.name }</a>
          )
        },
      }),
      {
        field: 'dict_type',
        title: '类型',
      },
      getEnabledTableColumn(),
    ]
  },
}
