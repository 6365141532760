<template>
  <div>
    <page-header title="字典管理" />
    <page-body>
      <list :id="listId"  />
    </page-body>
  </div>
</template>

<script>
import List from './components/List'

export default {
  name: 'DictIndex',
  components: {
    List,
  },
  data () {
    return {
      listId: 'DictList',
    }
  },
}
</script>
